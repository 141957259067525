import React, {useEffect, useState} from "react";
import {
    Row,
    Col,
    Card,
    Typography,
    Progress,
    Button,
    Skeleton,
    Space,
    Divider, Tooltip
} from "antd";
import Icon, {
    ClockCircleOutlined,
    FormOutlined,
    LockOutlined
} from "@ant-design/icons";
import {useLocation, useNavigate, useOutletContext} from "react-router-dom";
import {round} from "../helpers";
import {useResizeEffect} from "../resize-effect";
import Api from "../api";
import {useTranslation} from "react-i18next";
import qs from "qs";
import {useSelector} from "react-redux";
import PaymentModal from "../components/sales-page/payment-modal";

const {Title, Text} = Typography;

const Courses = ({type}) => {
    const navigate = useNavigate();
    const isMobile = useResizeEffect();
    const [courses, setCourses] = useState([]);
    const [loading, setLoading] = useState(true);
    const location = useLocation();
    const {t} = useTranslation();
    const videoType = qs.parse(location.search)["?type"] ? qs.parse(location.search)["?type"] : "course";
    const user = useSelector(state => state.user.data);
    const [showPaymentSection, setShowPaymentSection] = useState(false);
    const hasAccess = ((user.promotional_gifts && user.promotional_gifts.includes('https://app.fitlap.ee/courses')) || user.fitlap_plus);
    const [outletData, setOutletData] = useOutletContext();

    useEffect(() => {
        window.scrollTo(0, 0);

        setOutletData({
            title: videoType === 'course' ? t('header.courses') : t('header.podcast'),
            back: true
        });

        Api.get('/v2/courses').then(res => {
            setLoading(false);

            // Process courses data and filter based on the 'type'
            const processedCourses = res.data.map((course, index) => {
                return ({
                    ...course,
                    totalTasks: course.tasks.length,
                    completedTasks: course.tasks.filter(task => task.completed).length,
                });
            });

            setCourses(processedCourses);
        });
    }, []);

    const getNextMonthDate = (offset) => {
        const currentDate = new Date();
        const year = currentDate.getFullYear();
        const month = currentDate.getMonth() + offset + 1; // Offset from the 4th course onward
        return new Date(year, month, 1); // Constructs the 1st of the given month
    };

    return (
        <div>
            <Row type="flex" justify="center">
                <PaymentModal showInfoBtn={true} visible={showPaymentSection} setVisible={setShowPaymentSection}/>

                <Col span={24} md={24} xxl={type ? 24 : 20}>
                    {!type && !isMobile && (
                        <Divider orientation="left">
                            <Title style={{margin: 0}}
                                   level={4}>{videoType === 'course' ? t('header.courses') : t('header.podcast')}</Title>
                        </Divider>
                    )}

                    <Row gutter={[16, 16]}>
                        {!loading ? courses.filter(course => course.type === videoType).map((course) => {
                            return (
                                (
                                    <Col xs={12} md={12} lg={type ? 8 : 6} key={course.id}>
                                        <Card
                                            bordered={false}
                                            title={''}
                                            className={'video-card-list'}
                                            cover={
                                                <img
                                                    alt={`${course.title} Thumbnail`}
                                                    src={course.thumbnail}
                                                />
                                            }
                                        >
                                            <Space direction="vertical" style={{width: "100%"}}>
                                                <Row type="flex" justify="space-between">
                                                    <Col span={24}>
                                                        <Title level={5}>{course.title}</Title>
                                                    </Col>
                                                </Row>

                                                {course.type === 'course' && (
                                                    <div>
                                                        <Row type="flex" justify="space-between">
                                                            <Text className="muted-text">
                                                                <FormOutlined/> {course.completedTasks}/{course.totalTasks}
                                                            </Text>

                                                            <Text className="muted-text">
                                                                <Icon
                                                                    component={ClockCircleOutlined}/> {course.time} min
                                                            </Text>
                                                        </Row>

                                                        <Progress strokeColor={{'0%': '#ee7752', '50%': '#e73c7e'}}
                                                                  percent={round((course.completedTasks / course.totalTasks) * 100)}
                                                                  percentPosition={{type: 'hidden'}}
                                                                  status={round((course.completedTasks / course.totalTasks) * 100) < 100 ? "active" : "success"}/>
                                                    </div>
                                                )}


                                                <Row type="flex" justify="center">
                                                    {course.status === 1 ? (
                                                        <Tooltip title={hasAccess ? '' : t('course.access-required')}>
                                                            <Button type="default"
                                                                    onClick={hasAccess ? () => navigate(`/courses/${course.id}`) : () => setShowPaymentSection(true)}>
                                                                {hasAccess ? (course.completedTasks === 0 ? "Vaata videot" : "Jätka") :
                                                                    <div><span className="fitlap-logo-font">F+</span>
                                                                    </div>
                                                                }
                                                            </Button>
                                                        </Tooltip>
                                                    ) : (
                                                        <Tooltip title={t('course.coming-soon')}>
                                                            <Button
                                                                type={"default"}
                                                            ><LockOutlined/></Button>
                                                        </Tooltip>
                                                    )}
                                                </Row>
                                            </Space>
                                        </Card>
                                    </Col>
                                )
                            )
                        }) : (
                            ([...Array(8).keys()].map(index => (
                                <Col xs={24} md={12} lg={6} key={index}>
                                    <Skeleton className="post-skeleton" title paragraph={{rows: 2}} active/>
                                </Col>
                            )))

                        )}
                    </Row>
                </Col>
            </Row>
        </div>

    );
};

export default Courses;