import {
    Avatar,
    Badge,
    Button,
    Col,
    Divider,
    Empty,
    Input,
    message,
    Popconfirm,
    Popover,
    Row,
    Select, Space, Tooltip,
    Typography
} from "antd";
import moment from 'moment';
import React, {useEffect, useRef, useState} from "react";
import Api from "../../api";
import {Comment} from '@ant-design/compatible';
import {getMealName, reactionList, trackEvent} from "../../helpers";
import {useTranslation} from 'react-i18next';
import {HeartEmptyIcon, HeartIcon, PlusIcon, ReplyIcon} from "../../icons.js"
import Icon, {
    CommentOutlined,
    DeleteOutlined,
    SendOutlined,
    SmileOutlined,
    UserOutlined
} from "@ant-design/icons";
import {useSelector} from "react-redux";
import {ReplyOutlined} from "../../icons"
import {Link} from "react-router-dom";
import UserProfileModal from "../../components/chatroom/user-profile-modal";
import {ReactionBarSelector} from "@charkour/react-reactions";
import EmojiCounter from "./emoji-counter";
import ReactPlayer from "react-player";
import PostContentWithLinks from "../chatroom/post-content-with-links";

const {TextArea} = Input;
const {Option} = Select;
const {Title} = Typography;
const {Text} = Typography;

const Comments = ({id, type, commentsData, commentsMeta, hideContent, setShowComments}) => {
    const [showUserModal, setShowUserModal] = useState(false);
    const {t} = useTranslation();
    const [comments, updateComments] = useState(commentsData ? commentsData : []);
    const [sort, updateSort] = useState('latest');
    const [loading, setLoading] = useState(false);
    const [reply, setReply] = useState(false);
    const [meta, setMeta] = useState('');
    const [commentValue, updateCommentValue] = useState('');

    useEffect(() => {
        if (!hideContent) {
            loadComments();
        }
    }, [id, sort]);

    const loadComments = (loadMore) => {
        let perPage = meta ? meta.per_page : 5;
        let sortBy = sort === 'oldest' ? "&sort=asc" : "";
        let page = '?page=1';

        if (loadMore) {
            page = '?page=' + (meta.current_page + 1)
        }

        Api.get(`/v2/comments/${type}/${id}${page}&per_page=${perPage}${sortBy}`).then(res => {
            setMeta(res.data.meta);

            updateComments(loadMore ? [...comments, ...res.data.data] : res.data.data);
        });
    };

    const createComment = () => {
        if (!commentValue) {
            return message.error(t('comment.required'))
        }

        setLoading(true);

        // Comment content
        const data = {
            body: commentValue,
            commentable_type: type,
            commentable_id: id,
        };

        // Add comment parent id if exists
        if (reply) {
            data.parent_id = reply
        }

        Api.post("/v2/comment/store", data).then(res => {
            trackEvent(`added a ${type} comment`);

            loadComments();

            // Empty comment textarea
            cancelComment();

            // Clear comment textarea
            updateCommentValue('');

            setLoading(false);
        }).catch(err => {
            setLoading(false);

            message.error(t('comment.reply.error'));
        });
    };

    const deleteComment = (id) => {
        Api.delete('/v2/comments/' + id).then(res => {
            loadComments();
        })
    }

    const cancelComment = () => {
        setReply(false);
    };

    const renderCreateComment = (cancel) => (
        <Row className="create-comment">
            <TextArea value={commentValue} onChange={(e) => updateCommentValue(e.target.value)}
                      placeholder={t('blogs.comments.placeholder')} rows={2}/>

            <Button style={{marginRight: 6}} className="main-btn" loading={loading}
                    type="primary" onClick={createComment}>{t('blogs.comments.add-new')}</Button>

            {(cancel) && (
                <Button onClick={cancelComment} className="main-btn">{t('common.cancel')}</Button>
            )}
        </Row>
    );

    return (
        <div className="blog-comments">
            <UserProfileModal visible={!!showUserModal}
                              setVisible={setShowUserModal} data={showUserModal}/>

            {!hideContent && !commentsData && (
                <Row>
                    <Col span={24}>
                        <Divider orientation="left">
                            <Title style={{margin: 0}} level={4}>{t('blogs.comments.title')} ({meta.total})</Title>
                        </Divider>
                    </Col>
                </Row>
            )}

            {!reply && !hideContent && renderCreateComment()}

            <Row type="flex" align="middle" justify="end" className="blog-comments-header" id="scroll-to-comments">
                {comments.length > 0 && !hideContent && (
                    <Col span={8}>
                        <Select size="small" defaultValue={sort} style={{float: "right"}} onChange={(value) => {
                            trackEvent('changed comments sorting order');
                            updateSort(value);
                        }}>
                            <Option value="latest">{t('blogs.comments.sort-newest')}</Option>
                            <Option value="oldest">{t('blogs.comments.sort-oldest')}</Option>
                        </Select>
                    </Col>
                )}
            </Row>

            {comments.length === 0 && (
                <Row type="flex" justify="center" className="comments-empty">
                    <Empty description={t('blogs.comments.empty')}/>
                </Row>
            )}

            <div className="comment-list">
                {(hideContent ? comments.slice(0, 2) : comments).map(comment => {
                    return (
                        <div key={comment.id}>
                            <CommentBlock
                                setShowComments={setShowComments}
                                setShowUserModal={setShowUserModal}
                                updateComments={updateComments}
                                comment={comment}
                                reply={reply}
                                setReply={setReply}
                                hideContent={hideContent}
                                renderCreateComment={renderCreateComment}
                                deleteComment={deleteComment}
                            />
                        </div>
                    );
                })}
            </div>

            {hideContent ? (
                <Row type="flex" justify="center" style={{gap: 12}}>
                        <Button onClick={() => {
                            setShowComments(true)
                            loadComments()}}>{t('add.comment')}</Button>

                    {comments.length > 2 && (
                        <Button onClick={() => {
                            setShowComments(true)
                            loadComments()
                        }}>{t('blogs.read-more')}</Button>
                    )}
                </Row>
            ) : (
                <>
                    {meta && (meta.current_page !== meta.last_page) && (
                        <Button disabled={loading}
                                style={{margin: "20px auto", display: "block", fontSize: 14, borderRadius: 24}}
                                onClick={() => loadComments(true)}>{t('blogs.read-more')} ({meta.total - meta.to})</Button>
                    )}
                </>
            )}

        </div>
    );
};

const CommentBlock = ({
                          comment,
                          reply,
                          isReply = false,
                          setReply,
                          renderCreateComment,
                          deleteComment,
                          updateComments,
                          setShowUserModal,
                          hideContent,
                          setShowComments
                      }) => {
    const {t} = useTranslation();
    const user = useSelector(state => state.user.data);
    const commentUser = comment.user;

    const reactComment = (value) => {
        Api.post(`/v3/comments/${comment.id}`, {type: value}).then((res) => {
            const updatedComment = res.data.comment;

            // Recursively update the comment or its reply
            updateComments((prevComments) =>
                prevComments.map((item) => updateNestedComment(item, updatedComment))
            );
        });
    };

    // Recursive function to update a comment or reply
    const updateNestedComment = (comment, updatedComment) => {
        if (comment.id === updatedComment.id) {
            // Merge replies: Keep updatedComment replies if they exist, otherwise use original comment's replies
            return {
                ...updatedComment,
                replies: updatedComment.replies?.length > 0
                    ? updatedComment.replies
                    : comment.replies || []
            };
        }

        if (comment.replies && comment.replies.length > 0) {
            // If the comment has replies, recursively update them
            return {
                ...comment,
                replies: comment.replies.map((reply) => updateNestedComment(reply, updatedComment)),
            };
        }

        // Return the comment unchanged if it doesn't match
        return comment;
    };

    const renderChildComments = (comment) => {
        if (comment.replies && comment.replies.length > 0 && !hideContent) {
            return comment.replies.map(data => (
                <CommentBlock setShowComments={setShowComments} setShowUserModal={setShowUserModal} updateComments={updateComments} key={data.id}
                              comment={data} isReply={comment.id} setReply={setReply}
                              renderCreateComment={renderCreateComment} deleteComment={deleteComment}/>
            ))
        }
    };

    return (
        <Comment
            actions={
                [
                    <>
                        {!comment.deleted_at && (
                            <div className="comment-actions">
                                <EmojiCounter reactEmoji={reactComment} reactions={comment.reactions}/>

                                <Tooltip rootClassName="tooltip-in-modal" key={"reply"} title={t('comment.reply')}>
                                    <Button size="small" type="text"
                                            onClick={() => {
                                                if (setShowComments) {
                                                    setShowComments(true);
                                                }

                                                setReply(isReply ? isReply : comment.id)
                                            }}
                                            icon={<Icon style={{fontSize: 14}} component={ReplyIcon}/>}></Button>
                                </Tooltip>

                                {user.user_id === commentUser.user_id && (
                                    <Popconfirm title={t('action.delete.comment')}
                                                onConfirm={() => deleteComment(comment.id)} okText={t('delete.yes')}
                                                cancelText={t('delete.no')}>
                                        <Button size="small" type="text"
                                                icon={<DeleteOutlined style={{fontSize: 14}}/>}></Button>
                                    </Popconfirm>
                                )}

                                {reply === comment.id && (renderCreateComment(true))}
                            </div>
                        )}
                    </>
                ]
            }
            author={
                <div onClick={() => setShowUserModal(comment)} style={{display: "inline-block", cursor: "pointer"}}>
                    {commentUser.name}
                </div>
            }
            avatar={
                <div onClick={() => setShowUserModal(comment)}>
                    {
                        commentUser.avatar ? (
                            <Avatar size={24} src={commentUser.avatar}
                                    alt={commentUser.name}/>
                        ) : (
                            <Avatar size={24} icon={<UserOutlined/>}/>
                        )
                    }

                </div>
            }
            content={<p>{!comment.deleted_at ? <PostContentWithLinks text={comment.body}/> : <i>{t('comment.deleted')}</i>}</p>}
            datetime={<span>{moment(comment.created_at).fromNow()}</span>}
        >
            {!isReply && renderChildComments(comment)}
        </Comment>
    )
};


export default Comments;
