import React, {useEffect, useState} from "react";
import {Card, Typography, Table, Button, Row, Col, Tag, Divider, Statistic, Avatar, Progress, Space} from "antd";
import Api from "../api";
import {useTranslation} from "react-i18next";
import ActivityLeaderboard from "../components/goals/activity-leaderboard";
import StepChallengeCard from "../components/challenges/step-challenge-card";
import RewardsCard from "../components/challenges/rewards-card";
import {useNavigate, useParams} from "react-router-dom";
import BingoChallenge from "../components/challenges/bingo-challenge";
import CountdownTimer from "../components/challenges/countdown-timer";
import moment from "moment/moment";
import {UserOutlined} from "@ant-design/icons";

const {Title, Text} = Typography;

const ChallengePage = () => {
    const [challenge, setChallange] = useState('');
    const [competition, setCompetition] = useState('');
    const params = useParams();
    const [id,] = useState(params.id);
    const navigate = useNavigate();
    const {t} = useTranslation();

    useEffect(() => {
        Api.get('/v3/competitions/' + id).then(response => {
            if (response.data.data.has_access) {
                setCompetition(response.data.data);
            } else {
                navigate('/challenges');
            }
        }).catch(err => {
            navigate('/challenges')
        })
    }, []);

    if (!competition) {
        return false;
    }

    return (
        <div id="challenges">
            <Row type="flex" justify="center">
                <Col span={24} md={24} lg={24} xxl={20}>

                    <Row gutter={[40, 20]} type="flex" justify="center">
                        {competition.type === 'steps' && (
                            <Col span={24} lg={16}>
                                <StepChallengeCard competition={competition} setCompetition={setCompetition}/>

                                <br/>

                                <Card>
                                    <Row>
                                        <ActivityLeaderboard competitionData={competition} tableOnly={true}/>
                                    </Row>
                                </Card>
                            </Col>
                        )}

                        {competition.type === 'bingo' && (
                            <Col span={24} lg={16}>
                                <BingoChallenge competition={competition}/>
                            </Col>
                        )}

                        {competition.type === 'steps' && (
                            <Col span={24} lg={8}>
                                <ChallengeCountdown competition={competition}/>
                                <br/>
                                <RewardsCard rewardsData={[
                                    {id: 1, title: "1. koht", description: "Awarded to the top participant.", icon: '🥇'},
                                    {
                                        id: 2,
                                        title: "2. koht",
                                        description: "For the second-place finisher.",
                                        icon: '🥈'
                                    },
                                    {id: 3, title: "3. koht", description: "For the third-place finisher.", icon: '🥉'}, // Bronze color},
                                    {id: 4, title: "4-7. koht", description: "Fitlap+ access for 1 month", icon: <UserOutlined/>   ,},
                                    {id: 5, title: "7-10. koht", description: "discount 20% off coupon", icon: <UserOutlined/>,},
                                    {
                                        id: 8,
                                        title: "11-25. koht",
                                        description: "Special profile badge for top 25 participants.",
                                        icon: <UserOutlined/>,
                                    },
                                ]}/>
                            </Col>
                        )}
                    </Row>
                </Col>
            </Row>
        </div>
    );
};

const ChallengeCountdown = ({competition}) => {
    const today = moment(); // Assuming today is the 3rd day of challenge
    const startDate = competition.begin_date ? moment(competition.begin_date) : moment("2025-01-01");
    const endDate = competition.end_date ? moment(competition.end_date) : moment("2025-02-28");
    const isChallengedEnd = today.isAfter(endDate); // Returns true if the challenge has ended
    return (
        <Card>
            <Divider orientation="left">
                <Title style={{margin: 0}} level={4}>Väljakutse lõpuni</Title>
            </Divider>

            <Row type="flex" align="middle" gutter={[20, 20]}>
                <Col span={24}>
                    <CountdownTimer isChallengedEnd={isChallengedEnd} endDate={endDate}/>
                </Col>
            </Row>
        </Card>
    )
}

export default ChallengePage;