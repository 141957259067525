import React, {useEffect, useState} from "react";
import {Alert, Button, Col, Input, List, message, Modal, Row, Skeleton, Tabs, Typography} from "antd";
import {
    config,
    getAmplitudeSource,
    isApp,
    onImageError,
    ReactNativeMessage,
    round,
    trackEvent,
    urlTranslation,
} from "../../helpers";
import Api from "../../api";
import Ingredient from "../meal-plan/ingredient.jsx";
import Icon, {DislikeOutlined, MessageOutlined, ExclamationCircleOutlined} from "@ant-design/icons";
import {useTranslation} from 'react-i18next';
import Comments from "../blocks/comments.jsx";
import {HeartEmptyIcon, HeartIcon, ShareIcon} from "../../icons"
import {updateFavorites} from "../../helpers/meal-plan-helper";
import {setIn} from "immutable";
import RecipeTags from "../meal-plan/recipe-tags";
import SearchIngredientsModal from "./search-ingredients-modal";
import {useSelector} from "react-redux";
import SwapIngredientTip from "../blocks/swap-ingredient-tip";
import SheetModal from "../blocks/sheet-modal";

const {confirm} = Modal;
const {TabPane} = Tabs;
const {Text, Title} = Typography;
const {TextArea} = Input;

const SingleRecipeModal = ({openAddToMenu, close, data, visible, recipes, setRecipes, modal}) => {
    const {t} = useTranslation();
    const [recipe, setRecipe] = useState(false);
    const [loading, setLoading] = useState(false);
    const [searchIngredient, setSearchIngredient] = useState(false);
    const [reportText, setReportText] = useState(false);
    const [showReport, setShowReport] = useState(false);
    const [reportDisabled, setReportDisabled] = useState(false);
    const user = useSelector(state => state.user.data);
    const dailyTdee = !recipe.is_snack ? round(user.tdee / 4) : round(user.tdee / 4) / 2;
    const id = data ? data.id : false;

    useEffect(() => {
        if (id) {
            loadRecipe();
        }
    }, [id])

    const loadRecipe = () => {
        setLoading(true);

        Api.get('/v2/recipes/' + id).then((res) => {
            trackEvent('viewed recipe', {
                recipe_name: res.data.data.original_name.toLowerCase(),
                source: getAmplitudeSource()
            });

            const newRecipe = calculateNutridata(res.data.data);

            setRecipe(newRecipe);
            setLoading(false);
        }).catch(err => {
            message.error(t('recipe.not.found'))
        });
    };

    const calculateNutridata = (data) => {
        // Calculate recipe nutridata based on ingredients
        let recipeNutridata = {
            carbohydrate: 0,
            protein: 0,
            fat: 0,
            fiber: 0,
            energy: 0,
        }

        if (data.ingredients.length < 1) {
            return data;
        }

        data.ingredients.map(ingredient => {
            recipeNutridata['carbohydrate'] += ingredient.amount * ingredient.carbohydrate / 100;
            recipeNutridata['protein'] += ingredient.amount * ingredient.protein / 100;
            recipeNutridata['fat'] += ingredient.amount * ingredient.fat / 100;
            recipeNutridata['fiber'] += ingredient.amount * ingredient.fiber / 100;
            recipeNutridata['energy'] += ingredient.energy;
        })

        data.macros = [];
        data.macros['carbohydrate'] = round(recipeNutridata.carbohydrate, 0);
        data.macros['protein'] = round(recipeNutridata.protein, 0);
        data.macros['fat'] = round(recipeNutridata.fat, 0);
        data.fiber = round(recipeNutridata.fiber, 0);
        data.macros['fiber'] = round(recipeNutridata.fiber, 0);
        data['energy'] = round(recipeNutridata.energy, 0);

        data.macros['carbohydratePercentage'] = round(recipeNutridata.carbohydrate * 4 * 100 / data.energy, 0);
        data.macros['proteinPercentage'] = round(recipeNutridata.protein * 4 * 100 / data.energy, 0);
        data.macros['fatPercentage'] = round(recipeNutridata.fat * 9 * 100 / data.energy, 0);

        return data;
    }

    const share = () => {
        trackEvent('recipe share');

        let recipeUrl = config('public_app_url') + urlTranslation('recipes') + '/' + recipe.slug;
        let utmUrl = '?utm_source=facebook&utm_medium=retseptide+share+button&utm_campaign=client+share&utm_content=' + recipeUrl;

        if (isApp()) {
            ReactNativeMessage('FACEBOOK_SHARE ' + recipeUrl + utmUrl);
        } else {
            window.FB.ui({
                method: 'share',
                href: recipeUrl + utmUrl,
                description: t('recipe.share.message')
            });
        }
    }

    const dislikeRecipe = () => {
        if (recipe.is_disliked) {
            Api.delete('/v1/user/disliked-recipes', {recipe_id: recipe.id}).then(res => {
                message.success(t('removed.dislike'));
                const updatedRecipe = setIn(recipe, ['is_disliked'], false);

                setRecipe(updatedRecipe);
            })

            return false;
        }

        confirm({
            title: t('dislike.confirm.title'),
            content: t('dislike.confirm.content'),
            okType: 'danger',
            autoFocusButton: null,
            okText: t('common.yes'),
            cancelText: t('common.no'),
            onOk() {
                Api.post('/v1/user/disliked-recipes', {recipe_id: recipe.id}).then(res => {
                    message.success(t('added.dislike'));
                    const updatedRecipe = setIn(recipe, ['is_disliked'], true);

                    setRecipe(updatedRecipe);
                })
            },
            onCancel() {
                console.log('Cancel')
            },
        });
    }

    const changeFavorites = () => {
        updateFavorites(recipe, recipes, res => {
            // Update all recipes
            setRecipes(res);

            // Update currently open recipe
            const updatedRecipe = setIn(recipe, ['is_favorite'], !recipe.is_favorite);
            const updatedRecipeAgain = setIn(updatedRecipe, ['likes_count'], !recipe.is_favorite ? recipe.likes_count + 1 : recipe.likes_count - 1);

            setRecipe(updatedRecipeAgain);
        })
    }

    const selectIngredient = (e) => {
        const data = {...recipe};

        const mealIndex = data.ingredients.findIndex( (i) => {
            return i.original_id === searchIngredient.id;
        });

        e.original_id = data['ingredients'][mealIndex]['original_id']

        data['ingredients'][mealIndex] = e;

        const newRecipe = calculateNutridata(data);

        setRecipe(newRecipe);
        setSearchIngredient(false);
    }

    const swapIngredient = (ingredient) => {
        // No substitutes group 50
        if (ingredient.simple_substitute_group === 50) {
            return false;
        }

        setSearchIngredient({
            id: ingredient.original_id ? ingredient.original_id : ingredient.id,
            energy: ingredient.energy
        })
    }


    const submitReport = () => {
        if (!reportText) {
            return false;
        }

        setReportDisabled(true);

        Api.post('/v2/recipes/report', {
            id: recipe.id,
            message: reportText
        }).then(res => {
            message.success(t('report.sent'))
            setShowReport(false);
            setReportDisabled(false);
        }).catch(err => {
            message.error('error')
            setShowReport(false);
            setReportDisabled(false);
        })
    }

    if (!recipe) {
        return false;
    }

    const image = recipe.image.replace("thumbnail", "default");

    return (
        <SheetModal
            zindex={500}
            scroll={true}
            destroyOnClose={true}
            className="single-recipe-modal"
            title={t('recipes.info.title')}
            open={visible}
            onOk={close}
            close={close}
            footer={false}
        >
            <Modal
                destroyOnClose={true}
                title={t('report.recipe')}
                open={showReport}
                footer={false}
                onCancel={() => setShowReport(false)}
                width={400}
            >
                    <Row>
                        <TextArea onChange={(e) => setReportText(e.target.value)} placeholder={t('report.ingredient.placeholder')} rows={4}/>
                    </Row>
                    <br/>
                    <Row gutter={20} type="flex" justify="center" align="middle">
                        <Col span={10}>
                            <Button onClick={() => setShowReport(false)} block
                                    type="default">{t('common.cancel')}</Button>
                        </Col>
                        <Col span={10}>
                            <Button disabled={reportDisabled} onClick={submitReport} block
                                    type="primary">{t('submit.report')}</Button>
                        </Col>
                    </Row>

            </Modal>

                <Row type="flex" align="middle" justify="center">
                {searchIngredient && (
                    <SearchIngredientsModal
                        visible={!!searchIngredient}
                        data={searchIngredient}
                                            selectIngredient={selectIngredient}
                                            close={() => setSearchIngredient(false)}
                    />
                )}


                <Col span={24}>
                    {
                        !loading ? (
                            <Row type="flex" justify="center" align="middle" gutter={20} className={"desktop-recipe-wrapper"}>
                                <Col span={24} md={11}>
                                    <div className="recipe-modal-img-wrapper">
                                        <img className="recipe-modal-img" onError={onImageError} src={image} alt=""/>

                                        <img className="ambilight" src={image} alt=""/>
                                    </div>
                                </Col>

                                <Col span={0} md={11}>
                                    <Title level={2}>{recipe.original_name}</Title>

                                    <RecipeTags showTags={true} recipe={recipe} meal={{energy: recipe.energy}}/>

                                    <Row className="post-actions" type="flex" align="middle" style={{gap: 8}}>
                                        <Button onClick={changeFavorites} className={"post-heart " + (recipe.is_favorite ? "liked" : "")}><Icon component={recipe.is_favorite ? HeartIcon : HeartEmptyIcon}/> {recipe.likes_count}</Button>
                                        <Button onClick={share}><Icon component={ShareIcon}/></Button>
                                        <Button onClick={dislikeRecipe} type={recipe.is_disliked ? "primary" : "default"}><Icon component={DislikeOutlined}/></Button>
                                        <Button onClick={() => setShowReport(true)} type={"default"}><Icon component={ExclamationCircleOutlined}/></Button>
                                    </Row>
                                </Col>
                            </Row>
                        ) : (
                            <Row type="flex" align="middle" justify="center" gutter={20}>
                                <Col span={24} md={20}>
                                    <Skeleton className="skeleton-img-box" active title={false}
                                              paragraph={{rows: 1}}/>
                                </Col>
                                <Col span={24} md={20}>
                                    <Skeleton active title={false} paragraph={{rows: 3, width: "40%"}}/>
                                </Col>
                            </Row>
                        )
                    }

                    <Row type="flex" justify="center" className="text-center">
                        <Col span={24} md={0}>
                            <Title level={4}>{recipe.original_name}</Title>

                            <RecipeTags showTags={true} recipe={recipe} meal={{energy: recipe.energy}}/>

                            <Row className="post-actions" type="flex" justify="center" align="middle" style={{gap: 8}}>
                                <Button onClick={changeFavorites} className={"post-heart " + (recipe.is_favorite ? "liked" : "")}><Icon component={recipe.is_favorite ? HeartIcon : HeartEmptyIcon}/> {recipe.likes_count}</Button>
                                <Button onClick={share}><Icon component={ShareIcon}/></Button>
                                <Button onClick={dislikeRecipe} type={recipe.is_disliked ? "primary" : "default"}><Icon component={DislikeOutlined}/></Button>
                                <Button onClick={() => setShowReport(true)} type={"default"}><Icon component={ExclamationCircleOutlined}/></Button>
                            </Row>
                            <SwapIngredientTip/>
                        </Col>
                    </Row>

                    <Row type="flex" justify="center">
                        <Col span={24} md={20}>
                            {!loading ? (
                                <Tabs>
                                    {recipe.ingredients.length > 0 && (
                                        <TabPane tab={t('recipes.info.ingredients')} key="ingredients">
                                            <List
                                                dataSource={recipe.ingredients}
                                                renderItem={(ingredient, index) => {
                                                    if (data.ingredient_category_id !== config('recipe.condiments_category')) {
                                                        return (
                                                            <List.Item key={ingredient.original_id}>
                                                                <Ingredient showSearchIngredients={() => swapIngredient(ingredient)} hideAmount={recipe.is_custom_energy} key={ingredient.id} ingredient={ingredient}
                                                                            multiplier={1}/>
                                                            </List.Item>
                                                        )
                                                    }
                                                }}
                                            />

                                            {recipe.condiments.length > 0 && (
                                                <Row type="flex" className="recipe-condiments">
                                                    <strong>{t('recipes.info.condiments')}</strong>
                                                    {
                                                        recipe.condiments.map(data => {
                                                            return <span key={data}>{data}</span>
                                                        })
                                                    }
                                                </Row>
                                            )}
                                        </TabPane>
                                    )}

                                    <TabPane tab={t('recipes.info.instructions')} key="instructions">
                                        <div className="instruction-list"
                                             dangerouslySetInnerHTML={{__html: recipe.instructions}}/>
                                    </TabPane>

                                    <TabPane tab={<>{recipe.comments_count} <Icon style={{marginRight: 0}} component={MessageOutlined}/></>} key="comments">
                                        <div>
                                            <Comments type="recipe" id={id}/>
                                        </div>
                                    </TabPane>
                                </Tabs>
                            ) : (
                                <Skeleton active paragraph={{rows: 7, width: "30%"}} title={false}/>
                            )}

                            {recipe.is_custom && (
                                <Alert style={{marginTop: 20}} message={t('recipes.info.warning')} type="warning" showIcon/>
                            )}

                            <br/>

                            {!recipe.is_allowed && recipe.ingredients_energy && (
                                <Alert key="kcal-warning" showIcon className="create-recipe-warning"
                                       message={t('create-recipe.warning')}
                                       type="error"/>
                            )}


                            <Row type="flex" align="middle" justify="center" gutter={20}>
                                <Col span={12} md={8} className="text-center">
                                    <Button className="main-btn close-button"
                                            onClick={close}
                                            size="large"
                                            type="default">{t('common.close')}</Button>
                                </Col>

                                {openAddToMenu && !recipe.is_disliked && (
                                    <Col span={12} md={8} className="text-center">
                                        <Button className="add-meal-to-menu main-btn"
                                                onClick={() => openAddToMenu(modal ? recipe : recipe)}
                                                size="large"
                                                type="primary">{t('recipes.add-to-menu')}</Button>
                                    </Col>
                                )}
                            </Row>


                        </Col>
                    </Row>
                </Col>

            </Row>
        </SheetModal>
    );
}

export default SingleRecipeModal;
