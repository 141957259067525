import React, {useEffect, useState} from "react";
import {Avatar, Button, Col, DatePicker, message, Row, Space, Table, Typography} from "antd";
import Icon, {TrophyOutlined, TrophyTwoTone, UserOutlined} from "@ant-design/icons";
import {withTranslation} from 'react-i18next';
import moment from "moment";
import {useSelector} from "react-redux";
import {filterObject} from "../../helpers";
import Api from "../../api";
import UserProfileModal from "../chatroom/user-profile-modal";
import {useResizeEffect} from "../../resize-effect";

const {Column} = Table;
const {Title, Text} = Typography;
const {RangePicker} = DatePicker;

const ActivityLeaderboard = ({t, tableOnly, competitionData}) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [competition, setCompetition] = useState(competitionData ? competitionData : false);
    const [competitions, setCompetitions] = useState([]);
    const [hasJoinedCompetition, setHasJoined] = useState(false);
    const user = useSelector(state => state.user.data);
    const userPlacement = filterObject(data, 'user_id', user.user_id)[0];
    const [showUserModal, setShowUserModal] = useState(false);

    useEffect(() => {
        if (competitionData) {
            setCompetition(competitionData);
            setParticipants(competitionData.participants.data);
        }
    }, [competitionData])

    useEffect(() => {
        if (!competitionData) {
            // Find step challenge competition
            Api.get('/v2/competitions').then(res => {
                setCompetitions(res.data);

                if (res.data.length > 0) {
                    loadCompetition(res.data[0].id);

                    let hasJoined = false;

                    res.data.map(comp => {
                        if (filterObject(comp.participants, 'participatable_id', user.user_id)[0]) {
                            hasJoined = filterObject(comp.participants, 'participatable_id', user.user_id)[0]
                        }
                    })

                    setHasJoined(!!hasJoined);
                }
            })
        }
    }, [])

    const loadCompetition = (id) => {
        Api.get('/v2/competitions/' + id).then(response => {
            setCompetition(response.data);

            setParticipants(response.data.sorted_participants);

            setLoading(false);
        })
    }

    const setParticipants = (sortedParticipants) => {
        let participants = [];

        // Format participants
        sortedParticipants.map((participant, i) => {
            if (!participant.user) {
                return false;
            }

            console.log(participant);

            participants.push({
                key: parseInt(i) + 1,
                user_id: participant.user.user_id,
                name: participant.user.name_f,
                avatar: participant.user.avatar,
                user: participant.user,
                steps: participant.user.steps_count,
            })
        })

        setData(participants);
    }

    const joinChallenge = () => {
        // Remove user from competition
        if (userPlacement) {
            Api.delete('/v2/user/competitions/' + competition.id).then(res => {
                loadCompetition(competition.id);

                setHasJoined(false);
            });

            return false;
        }

        // Add user to competition
        Api.post('/v2/user/competitions/' + competition.id).then(res => {
            setCompetition(res.data);
            setParticipants(res.data.sorted_participants);

            setHasJoined(true);

            message.success(t('challenge.joined'))
        });
    }

    const renderHeader = () => {
        return <>{t('user.placement')} <strong>#{userPlacement.key}</strong></>
    }

    return (
        <Col span={24} lg={24}>
            <UserProfileModal visible={!!showUserModal}
                              setVisible={setShowUserModal} data={showUserModal}/>

            {competitions.length > 1 && !tableOnly && (
                <Row type="flex" justify="center" gutter={20} style={{gap: 8}}>
                    {competitions.map(data => {
                        return (
                            <Button onClick={() => loadCompetition(data.id)} type={data.id === competition.id ? "primary" : "default"}>{data.name}</Button>
                        )
                    })}
                </Row>
            )}

            {competition && !tableOnly && (
                <Row className="text-center" style={{paddingBottom: 40}}>
                    <Col span={24}>
                        <Title level={3}>{competition.name}</Title>
                        <Text>{moment(competition.begin_date).format("Do MMM YYYY")} - {moment(competition.end_date).format("Do MMM YYYY")}</Text>
                    </Col>
                    {competition.image && (
                        <Col span={24}>
                            <img style={{marginTop: 20, maxWidth: "100%"}} src={competition.image} alt=""/>
                        </Col>
                    )}

                    {((hasJoinedCompetition && userPlacement) || !hasJoinedCompetition) && (
                        <Col span={24}>
                            <Button style={{marginTop: 10}} type={userPlacement ? "default" : "primary"} onClick={joinChallenge}>
                                <TrophyOutlined style={{marginRight: 6}}/> {userPlacement ? t('challenge.quit') : t('challenge.join')}
                            </Button>
                        </Col>
                    )}
                </Row>
            )}

            <LeaderboardPodium data={data} setShowUserModal={setShowUserModal}/>

            {data.length > 3 && (
                <Table dataSource={data.slice(3)} size="middle" bordered={false} loading={loading} pagination={{pageSize: 20, showSizeChanger: false}}
                       title={userPlacement ? renderHeader : undefined}
                       locale={{
                           emptyText: <div className="empty-table"><Icon component={TrophyOutlined}/>
                               <div>{t('common.empty')}</div>
                           </div>
                       }}
                       rowClassName={(record, index) => record.user_id === user.user_id ? 'active-row' : ''}>
                    <Column title={"#"} dataIndex="key" key="key" width={20}/>
                    <Column title={t('blogs.edit-profile.name')} dataIndex="name" key="name"
                            render={(name, record) => <div style={{cursor: "pointer"}} onClick={() => setShowUserModal(record)}><Avatar size="small" icon={<UserOutlined/>} src={record.avatar}/> {record.user.display_name} {record.user_id === user.user_id ? <strong>{t('activity.you')}</strong> : ''}</div>}/>
                    <Column
                        className="text-right"
                        title={t('activity.steps')}
                        dataIndex="steps"
                        key="steps"
                        sorter={(a, b) => b.steps - a.steps}
                        render={(text) => text ? text.toLocaleString() : 0} // Add this to format with commas
                    />
                </Table>
            )}
        </Col>
    );
}

const LeaderboardPodium = ({data, setShowUserModal}) => {
    const isMobile = useResizeEffect();

    const renderPlacement = (placement, medal) => {
        const index = placement - 1;

        if (!data[index]) {
            return false;
        }

        return (
            <Col className="podium" span={8} md={placement === 1 ? 6 : 4}>
                <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
                    <Avatar icon={<UserOutlined/>} style={{cursor: "pointer"}} onClick={() => setShowUserModal(data[index])} src={data[index].avatar} size={placement === 1 ? 84 : 64} />
                    <span className="medal silver">{medal}</span>
                </div>

                <div style={{cursor: "pointer"}} onClick={() => setShowUserModal(data[index])} className="name">{data[index].user.display_name}</div>
                <div className="score">{data[index].user ? data[index].user.steps_count.toLocaleString() : 0} <br/> sammu</div>
                <div className={"podium-rank rank-" + placement}>{placement}</div>
            </Col>
        )
    }

    return (
        <Row className="podium-wrapper" justify="center" gutter={isMobile ? 10 : 20}>
            {renderPlacement(2, '🥈')}
            {renderPlacement(1, '🥇')}
            {renderPlacement(3, '🥉')}
        </Row>
    );
};

export default withTranslation()(ActivityLeaderboard);
